import { Avatar, Button, Checkbox, Col, DatePicker, Form, Input, Layout, Row, Select, Space } from 'antd'
import { Content } from 'antd/es/layout/layout'
import Link from 'antd/es/typography/Link'
import Title from 'antd/es/typography/Title'
import { UserOutlined, ExclamationCircleOutlined, LeftCircleFilled } from '@ant-design/icons'
import ModalExitoCrearCuenta from '../modal/ModalExitoCrearCuenta'
import { useEffect, useState } from 'react';
import Loader from '../Loader/loader';
import { useKeycloak } from '@react-keycloak/web'
import { getTerminosCondicionesPortal } from '../../services/documentoApi'
import { crearCuentaEmpresa, existeEmpresaRegistradaByRut } from '../../services/empresaApi'
import { existeEmail, getPaises, existeRutRegistrado, getCiudades, getRegiones, getComunas } from '../../services/publicApi'
import { esRutValido, getRutSinDv, getDvRut, cleanRut } from '../../services/Helpers/RutHelper'
import locale from 'antd/es/date-picker/locale/es_ES'
import { daFormatoRut } from '../../services/Helpers/RutHelper'
import { useNavigate } from 'react-router'
import './FormCrearCuentaEmpresa.styles.scss'

const FormCrearCuentaEmpresa = () => {
    const { keycloak } = useKeycloak()
    const navigate = useNavigate()
    const [form] = Form.useForm();
    const [accionCorrecta, setAccionCorrecta] = useState(true)
    const [existeEmailBd, setExisteEmail] = useState(null)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [paises, setPaises] = useState([{ label: 'Chile', value: 'CL' }])
    const [regiones, setRegiones] = useState(null)
    const [ciudades, setCiudades] = useState(null)
    const [comunas, setComunas] = useState(null)
    const [formIsSubmiting, setFormIsSubmiting] = useState(false)
    const [nameDropIsLoading, setNameDropIsLoading] = useState('')
    const [isDownloadDocumentacion, setIsDownloadDocumentacion] = useState(false)

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const onChangeRegion = async (value) => {
        try {
            setNameDropIsLoading('drpComunas')
            form.setFieldValue('comunaId', null)
            const result = await getComunas(value)
            setComunas(result)
        }
        catch {
        }
        finally {
            setNameDropIsLoading('')
        }
    }

    const onChangePais = async (value) => {
        try {
            setNameDropIsLoading('drpRegiones')
            const glosa = paises.filter(x => x.value === value)[0].label
            form.setFieldValue('glosaPais', glosa)
            form.setFieldValue('regionId', null)
            const result = await getRegiones(value)
            setRegiones(result)
        }
        catch {
        }
        finally {
            setNameDropIsLoading('')
        }
    }

    const onDownloadTerminosYcondiciones = async () => {
        setIsDownloadDocumentacion(true)
        try {
            const docRes = await getTerminosCondicionesPortal()

            if (docRes) {
                const blob = new Blob([docRes.buffer], { type: 'application/pdf' });
                const linkRedirect = URL.createObjectURL(blob);

                window.open(linkRedirect, "_blank");
            } else {
                console.log("Respuesta nula")
            }
        } catch (error) {
            console.error("Error al descargar documento")
            console.error(error)
        } finally {
            setIsDownloadDocumentacion(false)
        }
    }

    const onCrearCuenta = () => {
        setFormIsSubmiting(true)
        form.submit()
    }

    const onFinish = async (values) => {
        try {
            setFormIsSubmiting(true)
            values.DVnumeroIdentificacion = getDvRut(values.numeroIdentificacion)
            values.numeroIdentificacion = getRutSinDv(values.numeroIdentificacion)

            values.administrador.fechaNacimiento = values.administrador.fechaNacimiento.toDate()
            values.administrador.DVnumeroIdentificacion = getDvRut(values.administrador.numeroIdentificacion)
            values.administrador.numeroIdentificacion = getRutSinDv(values.administrador.numeroIdentificacion)

            const result = await crearCuentaEmpresa(values)

            if (result.accionFueCorrecta) {
                setAccionCorrecta(true)
            }
            else {
                setAccionCorrecta(false)
                throw new Error('error');
            }
        }
        catch (error) {
            setAccionCorrecta(false)
            console.log(error)
        }
        finally {
            setIsModalOpen(true)
            setFormIsSubmiting(false)
        }
    };

    const onValidateEmail = async (value) => {
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+\.)+[a-zA-Z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]{2,}))$/
        const result = regex.test(value);

        if (result) {
            const data = await existeEmail(value)
            setExisteEmail(data)
            setFormIsSubmiting(false)

            if (data.tieneCuentaOnBoarding) {
                return Promise.reject(new Error('Email ingresado ya se encuentra registrado'))
            }
            else {
                return Promise.resolve()
            }
        }
    }

    const onValidateRutEmpresa = async (value) => {
        const esValido = esRutValido(value)
        const existe = esValido ? await existeEmpresaRegistradaByRut(cleanRut(value)) : false
        setFormIsSubmiting(false)

        if (esValido && !existe) {
            return Promise.resolve()
        }
        else if (!esValido) {
            return Promise.reject(new Error('Ingresa un Rut válido'))
        }
        else if (existe) {
            return Promise.reject(new Error('Este RUT ya tiene una cuenta creada'))
        }
    }

    const onValidateRut = async (value) => {
        const esValido = esRutValido(value)
        const existe = esValido ? await existeRutRegistrado(cleanRut(value)) : false
        setFormIsSubmiting(false)

        if (esValido && !existe) {
            return Promise.resolve()
        }
        else if (!esValido) {
            return Promise.reject(new Error('Ingresa un Rut válido'))
        }
        else if (existe) {
            return Promise.reject(new Error('Este RUT ya tiene una cuenta creada'))
        }
    }

    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return (
        <>
            <Loader isLoadingExt={isDownloadDocumentacion}></Loader>
            <Layout className="app-layout">
                <Content className="content">
                    <Row gutter={{ xs: 0, sm: 0, md: 0, lg: 0 }}>
                        <Col xs={24} sm={24} md={24} lg={0} style={{ minHeight: '70px', paddingTop: 20, backgroundColor: '#EDF6FF', textAlign: 'left' }}>
                            <Space>
                                <LeftCircleFilled onClick={() => navigate(-1)} style={{ fontSize: 30, marginLeft: 30, color: '#ff4201' }} />
                                <p onClick={() => navigate(-1)} style={{ marginTop: 10 }}><strong> Volver atrás</strong></p>
                            </Space>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={14} style={{ minHeight: '100vh' }} className='containerFormPersona'>
                            <div className="header-container">
                                <Row style={{ textAlign: 'left' }} gutter={{ xs: 4, sm: 0, md: 5, lg: 10 }}>

                                    {/* mobile */}
                                    <Col className="gutter-row" xs={3} sm={2} md={2} lg={0}>
                                        <Avatar style={{ backgroundColor: '#0F00D8' }} className="avatar" size={50} icon={<UserOutlined />} />
                                    </Col>
                                    <Col className="gutter-row" xs={21} sm={14} md={10} lg={0}>
                                        <Title level={4} className='title' >Regístrate en Portal Empresa</Title>
                                    </Col>
                                    <Row gutter={{ xs: 4, sm: 24, md: 5, lg: 10 }}>
                                        <Col className="gutter-row" xs={2} sm={2} md={2} lg={0}>
                                            <Avatar size={45} style={{ backgroundColor: 'white', color: '#F1D779' }} className="avatar" icon={<ExclamationCircleOutlined />} />
                                        </Col>
                                        <Col className="gutter-row" xs={22} sm={22} md={22} lg={0}>
                                            <p className='subtitle' style={{ marginBottom: 0 }}>Completa la información solicitada para crear una cuenta</p>
                                        </Col>
                                        <Col className="gutter-row" xs={2} sm={2} md={2} lg={0}>
                                            <Avatar size={45} style={{ backgroundColor: 'white', marginTop: 5, color: '#F1D779' }} className="avatar" icon={<ExclamationCircleOutlined />} />
                                        </Col>
                                        <Col className="gutter-row" xs={22} sm={22} md={22} lg={0}>
                                            <p className='subtitle' style={{ marginTop: 10 }}> Por el momento solo se pueden crear una cuenta empresas que tengan RUT.</p>
                                        </Col>
                                    </Row>
                                    {/* desktop */}
                                    <Col className="gutter-row" xs={0} sm={0} md={0} lg={3} xl={3} xxl={2}>
                                        <Avatar className="avatar-desktop" style={{ backgroundColor: '#0F00D8' }} size={80} icon={<UserOutlined />} />
                                    </Col>
                                    <Col className="titulo" xs={0} sm={0} md={0} lg={21} xl={20} xxl={21} >
                                        <Row gutter={{ xs: 5, sm: 5, md: 5, lg: 5 }}>
                                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={24}>
                                                <Title level={2} style={{ marginTop: '0px', marginBottom: '0px', fontWeight: 'bold' }}>Regístrate en Portal Empresa</Title>
                                            </Col>
                                        </Row>
                                        <Row gutter={{ xs: 5, sm: 5, md: 5, lg: 5 }}>
                                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={24}>
                                                <Title level={5} style={{ marginTop: '5px' }}>
                                                    Completa la información solicitada para crear una cuenta
                                                </Title>
                                            </Col>
                                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={24}>
                                                <p style={{ marginTop: 0, fontSize: 12 }}>
                                                    Por el momento solo se pueden crear una cuenta empresas que tengan RUT.
                                                </p>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>

                            <Form form={form} onFinish={onFinish} style={{ width: '100%' }} className="form formEmpresa" layout="vertical">
                                <Row gutter={{ xs: 4, sm: 24, md: 5, lg: 10 }} style={{ textAlign: 'left' }}>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={24}>
                                        <Title level={4} style={{ fontWeight: 'bold', marginTop: '15px', marginBottom: '15px' }}>
                                            Datos Empresa
                                        </Title>
                                    </Col>
                                </Row>
                                <Form.Item style={{ display: 'none' }} name='glosaPais'><Input /></Form.Item>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                                        <Form.Item
                                            name='nombreEmpresa'
                                            label='Nombre empresa'
                                            className="username"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Ingresa el nombre de la empresa",
                                                },
                                            ]}
                                        >
                                            <Input placeholder='Razón Social' />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={9} xl={9} xxl={8}>
                                        <Form.Item
                                            name='numeroIdentificacion'
                                            className="inptRut"
                                            label='Número de Identificación empresa'
                                            normalize={(value) => daFormatoRut(value)}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Ingresa el RUT de la empresa",
                                                },
                                                {
                                                    validator: (_, value) => value ? onValidateRutEmpresa(value) : Promise.resolve()
                                                }
                                            ]}
                                        >
                                            <Input placeholder='11.111.111-1' />
                                        </Form.Item>
                                    </Col>

                                </Row>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                                        <Form.Item name='paisId' label='País' className="username" rules={[
                                            {
                                                required: true,
                                                message: "Ingresa un país",
                                            },
                                        ]}>
                                            <Select
                                                showSearch={false}
                                                onChange={onChangePais}
                                                filterOption={filterOption}
                                                placeholder="Selecciona un país"
                                                options={paises}
                                                loading={paises === null}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                                        <Form.Item name='regionId' label='Región' rules={[
                                            {
                                                required: true,
                                                message: "Ingresa una región",
                                            },
                                        ]}>
                                            <Select
                                                onChange={onChangeRegion}
                                                notFoundContent={nameDropIsLoading === 'drpRegiones' ? 'Cargando...' : 'Debes seleccionar primero un País.'}
                                                loading={nameDropIsLoading === 'drpRegiones'}
                                                showSearch={false}
                                                placeholder="Selecciona una región"
                                                filterOption={filterOption}
                                                options={regiones}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                                        <Form.Item name='comunaId' label='Comuna'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Ingresa una ciudad",
                                                },
                                            ]}
                                        >
                                            <Select
                                                notFoundContent={nameDropIsLoading === 'drpComunas' ? 'Cargando...' : 'Debes seleccionar primero una Región.'}
                                                loading={nameDropIsLoading === 'drpComunas'}
                                                showSearch={false}
                                                placeholder="Selecciona una comuna"
                                                filterOption={filterOption}
                                                options={comunas}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={{ xs: 4, sm: 24, md: 5, lg: 10 }} style={{ textAlign: 'left' }}>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={24}>
                                        <Title level={4} style={{ fontWeight: 'bold', marginTop: '15px', marginBottom: '15px' }}>
                                            Datos Usuario administrador
                                        </Title>
                                    </Col>
                                </Row>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                                        <Form.Item
                                            name={['administrador', 'nombre']}
                                            label='Nombre administrador'
                                            className="username"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Ingresa tus nombres",
                                                },
                                            ]}
                                        >
                                            <Input placeholder='Nombres' />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                                        <Form.Item
                                            name={['administrador', 'apellidos']}
                                            label='Apellidos administrador'
                                            className="username"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Ingresa tus apellidos",
                                                },
                                            ]}
                                        >
                                            <Input placeholder='Apellidos' />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                                        <Form.Item
                                            name={['administrador', 'numeroIdentificacion']}
                                            label='Número de Identificación (RUT)'
                                            normalize={(value) => daFormatoRut(value)}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: " Ingresa tu RUT",
                                                },
                                                {
                                                    validator: (_, value) => value ? onValidateRut(value) : Promise.resolve()
                                                }
                                            ]}
                                        >
                                            <Input placeholder='11.111.111-1' />
                                        </Form.Item>
                                    </Col>

                                </Row>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                                        <Form.Item name={['administrador', 'fechaNacimiento']}
                                            label='Fecha de nacimiento' className="username"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Ingresa tu fecha de nacimiento",
                                                },
                                            ]}
                                        >
                                            <DatePicker locale={locale} format={"DD/MM/YYYY"} placeholder='Selecciona fecha' style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                                        <Form.Item name={['administrador', 'telefono']} label='Teléfono fijo o Celular' className="username" rules={[
                                            {
                                                required: true,
                                                message: "Ingresa tu teléfono o celular",
                                            },
                                        ]}>
                                            <Input placeholder='+56912332155' />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                                        <Form.Item name={['administrador', 'email']} label='Correo electrónico' className="username" rules={[
                                            {
                                                required: true,
                                                message: "Ingresa tu correo electrónico",
                                            },
                                            {
                                                type: "email",
                                                message: "Ingresa un email válido",
                                            },
                                            {
                                                validator: (_, value) => value ? onValidateEmail(value) : Promise.resolve()
                                            }
                                        ]}>
                                            <Input autoComplete="off" placeholder='correo@correo.cl' />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col style={{ textAlign: 'left', marginTop: 20 }} className="gutter-row" xs={24} sm={24} md={24} lg={24}>
                                        <Form.Item
                                            normalize={(value) => value ? value : undefined}
                                            name='terminos' valuePropName="checked" rules={[
                                                {
                                                    required: true,
                                                    message: "Debes aceptar los Términos y Condiciones y la Política de Privacidad para poder continuar.",
                                                },
                                            ]}>
                                            <Checkbox style={{ fontWeight: '400' }}>He leído y acepto los <Link href="#" style={{ fontFamily: "roboto", fontWeight: 'bold' }} onClick={() => onDownloadTerminosYcondiciones()}> Términos y Condiciones y la Política de Privacidad</Link> de este sitio</Checkbox>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col xs={0} sm={0} md={12} lg={12}>
                                        <br />
                                        <Button
                                            htmlType="button"
                                            style={{ width: "80%" }}
                                            shape="round"
                                            className="btnVolverEmpresa"
                                            onClick={() => navigate(-1)}
                                        >
                                            Volver
                                        </Button>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={12}>
                                        <br />
                                        <Button
                                            type="primary"
                                            htmlType="button"
                                            style={{ width: "80%", backgroundColor: '#0f00d8', color: 'white' }}
                                            shape="round"
                                            onClick={() => onCrearCuenta()}
                                            loading={formIsSubmiting}
                                        >
                                            Crear cuenta
                                        </Button>
                                    </Col>
                                </Row>
                                <p className="font-semibold text-muted" style={{ textAlign: 'center' }}>
                                    ¿Ya tienes una cuenta?{" "}
                                    <Link to="#" onClick={() => keycloak.login({ redirectUri: `${process.env.REACT_APP_URL}/puente` })} className="text-dark font-bold letraNaranja">
                                        Iniciar Sesión
                                    </Link>
                                </p>
                            </Form>
                        </Col>
                        <Col xs={0} sm={0} md={0} lg={8} xl={8} xxl={10}>
                            <div style={{ width: '45%', position: 'fixed' }}>
                                <div className="right-section">
                                    <div className="background-image">
                                        <div className="return-to-home">
                                            <div className="return-icon">
                                                <LeftCircleFilled href={process.env.REACT_APP_URL_ONBOARDING_PUBLICO} style={{ fontSize: 24, color: '#ff4201' }} />
                                            </div>
                                            <div className="return-text">
                                                <p onClick={() => window.location.replace(process.env.REACT_APP_URL_ONBOARDING_PUBLICO)} >Volver al Inicio</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Content>
            </Layout>
            <ModalExitoCrearCuenta redirectToLogin={true} fueCorrecto={accionCorrecta} isModalOpen={isModalOpen} handleOk={handleOk} tipoCuenta={"Empresa"} poseeCuentaKeyCloack={existeEmailBd?.tieneCuentakeyCloack} />
        </>
    )
}

export default FormCrearCuentaEmpresa