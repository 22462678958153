import { LeftCircleFilled } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { Button, Col, Layout, Radio, Row, Typography, Form, Space } from 'antd'
import { Content } from 'antd/es/layout/layout'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { setDeclaracionCompliance } from '../../redux/reducers/AppReducer'
import './FormDeclaracionCompliance.styles.scss';
const URL_ONBOARDING_PUBLICO = process.env.REACT_APP_URL_ONBOARDING_PUBLICO

const { Title } = Typography

const FormDeclaracionCompliance = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [sePuedeRegistrar, setSePuedeRegistrar] = useState(false)
  const [esPersonaPoliticaShowError, setEsPersonaPoliticaShowError] = useState(false)
  const [tiempoPepShowError, setTiempoPepShowError] = useState(false)

  const watchEsPep = Form.useWatch("esPersonaPolitica", form)
  const watchTiempoPep = Form.useWatch("tiempoPep", form)

  useEffect(() => {
    if (watchEsPep && watchEsPep === "si")
      setEsPersonaPoliticaShowError(true)
    else
      setEsPersonaPoliticaShowError(false)
  }, [watchEsPep])

  useEffect(() => {
    if (watchTiempoPep && watchTiempoPep === "2")
      setTiempoPepShowError(true)
    else
      setTiempoPepShowError(false)
  }, [watchTiempoPep])

  useEffect(() => {
    if (!esPersonaPoliticaShowError && !tiempoPepShowError)
      setSePuedeRegistrar(true)
    else
      setSePuedeRegistrar(false)
  }, [esPersonaPoliticaShowError, tiempoPepShowError])

  const onFinish = async (values) => {
    dispatch(setDeclaracionCompliance({
      esPep: values.esPersonaPolitica !== 'no',
      tiempoPep: Number(values.tiempoPep)
    }))
    navigate('/crear-cuenta')
    window.scrollTo(0, 0)
  }

  return (
    <>
      <Layout className='layout'>
        <Row>
          <Col xs={24} sm={24} md={0} lg={0} style={{ minHeight: '70px', paddingTop: 20, backgroundColor: '#EDF6FF', textAlign: 'left' }}>
            <Space>
              <LeftCircleFilled href={URL_ONBOARDING_PUBLICO} style={{ fontSize: 30, marginLeft: 30, color: '#ff4201' }} />
              <p onClick={() => window.location.replace(process.env.REACT_APP_URL_ONBOARDING_PUBLICO)} style={{ marginTop: 10 }}><strong> Volver al Inicio</strong></p>
            </Space>
          </Col>
          <Col xs={0} sm={0} md={12} lg={12}>
            <div style={{ width: '50%', position: 'fixed' }}>
              <div className="right-section">
                <div className="return-to-home">
                  <div className="return-icon">
                    <LeftCircleFilled href={URL_ONBOARDING_PUBLICO} style={{ fontSize: 24, color: '#ff4201' }} />
                  </div>
                  <div className="return-text">
                    <p onClick={() => window.location.replace(process.env.REACT_APP_URL_ONBOARDING_PUBLICO)} >Volver al Inicio</p>
                  </div>
                </div>
                <div className="background-image"></div>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="containerForm">
            <Row>
              <Col className="gutter-row" span={24}>
                <Title level={2} style={{ marginBottom: 0, marginTop: 20 }}>Declaración Compliance</Title>
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col className="gutter-row" span={24}>
                <Title style={{ marginTop: 5 }} level={5}>Para registrarse en Marketplace</Title>
              </Col>
            </Row>
            <Row>
              <Form form={form} onFinish={onFinish} className="form formCompliance" layout='vertical'>
                <Col span={24}>
                  <p className="titulo destacar">Declaración Ley N° 20.393, Prevención del Lavado de Activos, Financiamiento del Terrorismo, Anti-corrupción, Sanciones, Conflicto de intereses y otros.</p>
                </Col>
                <Col span={24}>
                  <p>Holding Bursátil Regional S.A. (en adelante “HBR”) y el Cliente aceptan que la realización de sus actividades conforme a este Contrato se realizará en pleno cumplimiento de todas las normas y leyes anticorrupción aplicables, incluida, entre otras, la Ley 20.393 que establece la responsabilidad penal de las personas jurídicas, según esta sea modificada en el futuro. El Cliente acepta que, en relación con sus actividades conforme a este Contrato, no ofrecerá, prometerá, dará o autorizará, directa o indirectamente, el otorgamiento de ningún elemento de valor, ni ofrecerá, prometerá, realizará o autorizará ningún soborno, pago de facilitación u otro pago inapropiado o ilícito a ningún colaborador de HBR y sus filiales (en adelante “nuam”), ni a un funcionario público, privado, partido político o candidato a un cargo público con el objetivo de obtener o retener un negocio, recibir una ventaja poco justa o influenciar alguna ley o decisión de un funcionario público.</p>
                </Col>
                <Col span={24}>
                  <p>El Cliente se obliga a cumplir fiel, íntegra, cabal y oportunamente con toda la normativa, de aplicación general o especial, cuya finalidad sea disuadir, prevenir, impedir o sancionar los delitos que indica la Ley N° 20.393 que establece la responsabilidad penal de las personas jurídicas.</p>
                </Col>
                <Col span={24}>
                  <p>El Cliente también acuerda cumplir con todas las leyes y regulaciones, cuya finalidad sea disuadir, prevenir impedir o sancionar el lavado de dinero; sean o no de competencia de la Unidad de Análisis Financiero según la Ley 19.913 o de cualquier otra autoridad o entidad que en el futuro la reemplace o haga sus veces.</p>
                </Col>
                <Col span={24}>
                  <p>El Cliente declara estar en conocimiento que HBR ha adoptado un modelo de prevención de delitos de acuerdo a la Ley N°20.393, y se comprometen a no cometer o participar como autor, cómplice o encubridor en la comisión de ninguno de los delitos señalados en el artículo 1° de la citada Ley. También se obliga a hacer extensiva la presente declaración a todos sus trabajadores y dependientes que hagan uso directa o indirectamente del Servicio objeto del presente Contrato, así como de aquellos colaboradores y dependientes que se relacionen con nuam en virtud del presente Contrato.</p>
                </Col>
                <Col span={24}>
                  <p>Asimismo, el Cliente declara que: </p>
                </Col>
                <Col span={24}>
                  <ol type="a">
                    <li>No han sido involucrada ya sea en forma activa o pasiva en actos de corrupción, lavado de activos y/o financiamiento al terrorismo y que ningún monto pagado en virtud del presente Contrato proviene de una actividad contraria a las leyes y regulaciones aplicables; </li>
                    <li>Ni él, ni sus subsidiarias, entidades relacionadas, directores, ejecutivos, administradores, representantes o trabajadores han participado en actividades o conductas que pudieran violar las leyes, normas o regulaciones sobre anti-soborno, anticorrupción o de prevención del lavado de activos y/o financiamiento del terrorismo vigente en la República de Chile.</li>
                    <li>Cumple con toda la regulación local que rige el desarrollo de su actividad como así mismo con las normas contables y de impuestos que aplican a su organización; </li>
                    <li>Cooperará con nuam ante cualquier investigación de presuntas infracciones de temas de  <span className='cursiva'>compliance</span> o seguridad financiera y le entregará toda la información necesaria para efectos de dicha investigación.</li>
                    <li>nuam podrá realizar una o más auditorias relativas a las medidas preventivas o correctivas tomadas en materia de anti-soborno, anticorrupción o de prevención de lavado de activos y/o financiamiento del terrorismo. Estas auditorías podrán realizarse previo requerimiento de nuam y en horario laboral.</li>
                  </ol>
                </Col>
                <Col span={24}>
                  <p className="titulo">Derecho de Terminación</p>
                </Col>
                <Col span={24}>
                  <p>Si nuam razonablemente sospecha que el Cliente ha participado en una conducta que viola la política interna de nuam, o cualquier ley o norma anticorrupción aplicable, nuam puede suspender inmediatamente el Servicio hasta tanto se resuelva dicha cuestión.Si nuam determina que el Cliente violó la política interna de nuam o cualquier ley o norma anticorrupción o de prevención de lavado de activos y/o financiamiento del terrorismo aplicable, nuam puede terminar el Contrato. Además, nuam también puede suspender el pago y suspender o dar por finalizado el Contrato si el Cliente no acata las obligaciones de cumplimiento en materias de anticorrupción o de prevención de lavado de activos y/o financiamiento del terrorismo vigentes establecidas en este Contrato.</p>
                </Col>
                <Col span={24}>
                  <p className="titulo">Persona Expuesta Políticamente (PEP)</p>
                </Col>
                <Col span={24}>
                  <p style={{ marginBottom: 5 }}>Por medio del presente instrumento, el Cliente declara que: </p>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name='esPersonaPolitica'
                    validateStatus={esPersonaPoliticaShowError ? "error" : null}
                    help={esPersonaPoliticaShowError ? "Al declarar que es PEP no puede continuar con el registro. Por favor, contáctese con el equipo de soporte al correo sacbursatil@bolsadesantiago.com para regularizar su situación." : null}
                    rules={[
                      {
                        required: true,
                        message: "Debe seleccionar una opción",
                      }
                    ]}>
                    <Radio.Group>
                      <Radio style={{ fontWeight: 'normal' }} value="si">Es PEP</Radio>
                      <br />
                      <Radio style={{ fontWeight: 'normal' }} value="no"> No es PEP</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <p>Una de las que se indican a continuación (“Persona Expuesta Políticamente”): </p>
                </Col>
                <Col span={24}>
                  <ol className="customol">
                    <li>Presidente de la República de Chile o de un país extranjero; </li>
                    <li>Senadores, Diputados y alcaldes en Chile o en un país extranjero; </li>
                    <li>Ministros de la Corte Suprema y Cortes de Apelaciones de Chile o de un país extranjero; </li>
                    <li>Ministros de Estado, Subsecretarios, Intendentes, Gobernadores, secretarios regionales Ministeriales, Embajadores, Jefes Superiores de Servicio, tanto centralizados como descentralizados y el directivo superior inmediato que deba subrogar a cada uno de ellos, de Chile o de un país extranjero; </li>
                    <li>Comandantes en jefe de las Fuerzas Armadas, Director General de Carabineros, Director General de Investigaciones, y el oficial superior inmediato que deba subrogar a cada uno de ellos, de Chile o de un país extranjero; </li>
                    <li>Fiscal Nacional del Ministerio Público y fiscales regionales, de Chile o de un país extranjero; </li>
                    <li>Contralor General de la República de Chile o de un país extranjero; </li>
                    <li>Consejeros del Banco Central de Chile o de un país extranjero; </li>
                    <li>Consejeros del Consejo de Defensa del Estado o de un país extranjero; </li>
                    <li>Ministros del Tribunal Constitucional o de un país extranjero; </li>
                    <li>Ministros del Tribunal de la Libre Competencia; </li>
                    <li>Integrantes titulares y suplentes del Tribunal de Contratación Pública; </li>
                    <li>Consejeros del Consejo de Alta Dirección Pública de Chile o de un país extranjero; </li>
                    <li>Los directores y ejecutivos principales de empresas públicas, según lo definido por la Ley N° 18.045; </li>
                    <li>Directores de Sociedades anónimas nombrados por el Estado o sus organismos; </li>
                    <li>Miembros de las directivas de los partidos políticos; y</li>
                    <li>En general jefes de estado o de un gobierno, políticos de alta jerarquía, funcionarios gubernamentales, judiciales o militares de alta jerarquía, altos ejecutivos de empresas estatales, o funcionario de la administración del Estado de Chile, según lo definido por la Ley Nº 18.575, o del Estado de un país extranjero.</li>
                    <li>Cónyuge o pariente hasta el segundo grado de consanguinidad (abuelo, padre, madre, hijo, hermano, nieto), o que haya celebrado pacto de actuación conjunta mediante el cual tenga poder de voto suficiente para influir en sociedades constituidas en Chile con una Persona Expuesta Políticamente.</li>
                  </ol>
                </Col>
                <Col span={24}>
                  <p style={{ marginBottom: 5 }}>Asimismo, el Cliente declara que ha transcurrido: </p>
                </Col>
                <Form.Item
                  name='tiempoPep'
                  style={{ marginBottom: 0 }}
                  validateStatus={tiempoPepShowError ? "error" : null}
                  help={tiempoPepShowError ? "Al haber transcurrido menos de un año desde que fue PEP no puede continuar con el registro. Por favor, contáctese con el equipo de soporte al correo sacbursatil@bolsadesantiago.com para regularizar su situación." : null}
                  rules={[
                    {
                      required: true,
                      message: "Debe seleccionar una opción",
                    },
                  ]}>
                  <Radio.Group>
                    <Radio style={{ fontWeight: 'normal' }} value="1">Más de un año</Radio>
                    <br />
                    <Radio style={{ fontWeight: 'normal' }} value="2"> Menos de un año </Radio>
                    <br />
                    <Radio style={{ fontWeight: 'normal' }} value="3"> No aplica </Radio>
                    <br />
                    <Radio style={{ fontWeight: 'normal' }} className="falseOpcion" value="3"> Desde que finalizó el ejercicio de funciones como una Persona Expuesta Políticamente. </Radio>
                  </Radio.Group>
                </Form.Item>
                <Col className="gutter-row" span={12}>
                  <Button
                    disabled={sePuedeRegistrar ? false : true}
                    style={{ marginTop: 5, backgroundColor: '#00FF91' }}
                    type="primary"
                    htmlType="submit"
                    shape="round"
                  >
                    Siguiente
                  </Button>
                  <br /> <br />
                </Col>
              </Form>
            </Row>
          </Col>
        </Row>
      </Layout >
    </>
  )
}

export default FormDeclaracionCompliance