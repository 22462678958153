import { Spin } from 'antd';
import "./style.scss";
import { useSelector } from 'react-redux';

const Loader = ({ isLoadingExt }) => {
    const isLoading = useSelector(store => store.aplicacion.isLoading)

    return (
        <>
            {isLoading || isLoadingExt ? (
                <div className="load-screen">
                    <div className="global-loader">
                        <Spin spinning={true} size="large" />
                    </div>
                </div>
            ) : null}
        </>
    )
}

export default Loader

// import { useState } from 'react';
// import { Alert, Spin } from 'antd';

// const Loader = () => {
//     const [loading, setLoading] = useState(true)

//     return (
//         <Spin spinning={spinning} />
//     );
// };
// export default Loader;