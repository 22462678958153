import { Avatar, Col, Form, Layout, Row, Steps, Space } from 'antd'
import { Content } from 'antd/es/layout/layout'
import Title from 'antd/es/typography/Title'
import { UserOutlined, ExclamationCircleOutlined, LeftCircleFilled } from '@ant-design/icons'
import ModalAvisos from '../modal/ModalAvisos'
import { useEffect, useState } from 'react';
import { complementarCuentaEmpresa } from '../../services/empresaApi'
import { getPaises, getRubrosComerciales, getNacionalidades, getCargos, getAreaCargos } from '../../services/publicApi'
import { getRutSinDv, getDvRut } from '../../services/Helpers/RutHelper'
import { convertToBase64 } from '../../services/Helpers/stringHelper'
import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux'
import Paso1 from './pasosFormCompletar/Paso1'
import Paso2 from './pasosFormCompletar/Paso2'
import Paso3 from './pasosFormCompletar/Paso3'
import './pasosFormCompletar/FormCompletarCuenta.scss'

const FormCompletarCuentaEmpresa = () => {
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const usuario = useSelector(state => state.aplicacion.usuario)
    const [pasoActual, setPasoActual] = useState(0)
    const [accionCorrecta, setAccionCorrecta] = useState(true)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [paises, setPaises] = useState(null)
    const [formIsSubmitting, setFormIsSubmitting] = useState(false)
    const [rubrosComerciales, setRubrosComerciales] = useState(null)
    const [nacionalidades, setNacionalidades] = useState(null)
    const [cargos, setCargos] = useState(null)
    const [areaCargos, setAreaCargos] = useState(null)

    useEffect(() => {
        const fetchData = async () => {
            Promise.all([getPaises(),
            getNacionalidades(),
            getCargos(),
            getAreaCargos(),
            getRubrosComerciales()]).then((values) => {
                setPaises(values[0])
                setNacionalidades(values[1])
                setCargos(values[2])
                setAreaCargos(values[3])
                setRubrosComerciales(values[4])
            }).finally(() =>
                console.log()
            );
        }
        fetchData()
    }, [])

    const handleOk = () => {
        if (accionCorrecta)
            navigate('/home')
        else {
            setFormIsSubmitting(false)
            setIsModalOpen(false)
        }
    };

    const onFinish = async (values) => {
        if (!formIsSubmitting) {
            try {
                setFormIsSubmitting(true)
                const formData = new FormData()

                values.datosFacturacion.solicitaHes = values.datosFacturacion.solicitaHes === 0 ? false : true
                values.numeroIdentificacion = getRutSinDv(usuario.rutEmpresa)

                values.representantesLegales?.map((item) => {
                    item.rutDV = getDvRut(item.rut)
                    item.rut = getRutSinDv(item.rut)
                    item.fechaNacimiento = item.fechaNacimientoRep ? item.fechaNacimientoRep.toDate() : null
                    item.glosaPaisResidencia = paises.filter(x => x.value === item.paisResidenciaId)[0].label
                    item.glosaAreaCargo = areaCargos.filter(x => x.value === item.areaCargoId)[0]?.label
                })

                formData.append("file", values.documentoTributario[0].originFileObj)
                formData.append("numeroIdentificacion", values.numeroIdentificacion)
                formData.append("direccion", values.direccion)
                formData.append("rubroComercialId", values.rubroComercialId)
                formData.append("informacionExtraAdministrador", JSON.stringify(values.informacionExtraAdministrador))
                formData.append("representantesLegales", JSON.stringify(values.representantesLegales))
                formData.append("datosFacturacion", JSON.stringify(values.datosFacturacion))

                const result = await complementarCuentaEmpresa(formData)

                if (result?.accionFueCorrecta) {
                    setAccionCorrecta(true)
                }
                else {
                    setAccionCorrecta(false)
                }
            }
            catch (error) {
                setAccionCorrecta(false)
                console.log(error)
            }
            finally {
                setIsModalOpen(true)
                setFormIsSubmitting(false)
            }
        }
    }

    return (
        <>
            <Layout className="app-layout">
                <Content className="content">
                    <Row gutter={{ xs: 0, sm: 0, md: 0, lg: 0 }}>
                        <Col xs={24} sm={24} md={24} lg={0} style={{ minHeight: '70px', paddingTop: 20, backgroundColor: '#EDF6FF' }}>
                            <Row gutter={{ xs: 0, sm: 0, md: 0, lg: 0 }}>
                                <Col offset={2} className="gutter-row" xs={2} sm={2} md={2} lg={2}>
                                    <LeftCircleFilled onClick={() => navigate(-1)} style={{ fontSize: 30, color: '#ff4201' }} />
                                </Col>
                                <Col className="gutter-row" xs={15} sm={8} md={6} lg={22}>
                                    <p style={{ marginTop: 7 }}><strong> Creación Portal de Personas</strong></p>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={17}>
                            <div className="left-section">
                                <div className="header-container">
                                    <Row style={{ textAlign: 'left' }} gutter={{ xs: 4, sm: 0, md: 5, lg: 10 }}>

                                        {/* mobile */}
                                        <Col className="gutter-row" xs={3} sm={2} md={2} lg={0}>
                                            <Avatar style={{ backgroundColor: '#0F00D8' }} className="avatar" size={50} icon={<UserOutlined />} />
                                        </Col>
                                        <Col className="gutter-row" xs={21} sm={14} md={10} lg={0}>
                                            <Title level={4} className='title' >Regístrate en Portal Empresa</Title>
                                        </Col>
                                        <Row gutter={{ xs: 4, sm: 24, md: 5, lg: 10 }}>
                                            <Col className="gutter-row" xs={2} sm={2} md={2} lg={0}>
                                                <Avatar size={45} style={{ backgroundColor: 'white', color: '#F1D779' }} className="avatar" icon={<ExclamationCircleOutlined />} />
                                            </Col>
                                            <Col className="gutter-row" xs={22} sm={22} md={22} lg={0}>
                                                <p className='subtitle'> Completa la siguiente información para finalizar la creación de la cuenta</p>
                                            </Col>
                                        </Row>
                                        {/* desktop */}
                                        <Col className="titulo" xs={0} sm={0} md={0} lg={24} xl={24} xxl={24} style={{ paddingLeft: 0 }} >
                                            <Space>
                                                <Avatar className="avatar-desktop-completar" style={{ backgroundColor: '#0F00D8' }} size={58} icon={<UserOutlined />} />
                                                <div style={{ marginTop: 15 }}>
                                                    <Row gutter={{ xs: 5, sm: 5, md: 5, lg: 5 }}>
                                                        <Col className="gutter-row" xs={24} sm={24} md={24} lg={24}>
                                                            <Title level={2} style={{ marginTop: '0px', marginBottom: '0px', fontWeight: 'bold' }}>Regístrate en Portal Empresa</Title>
                                                        </Col>
                                                    </Row>
                                                    <Row gutter={{ xs: 5, sm: 5, md: 5, lg: 5 }}>
                                                        <Col className="gutter-row" xs={24} sm={24} md={24} lg={24}>
                                                            <Title level={5} style={{ marginTop: '5px' }}>
                                                                Completa la siguiente información para finalizar la creación de la cuenta
                                                            </Title>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Space>
                                        </Col>
                                    </Row>
                                </div>
                                <Steps
                                    current={pasoActual}
                                    items={[
                                        {
                                            title: <span>Datos Empresa</span>,
                                            // title:'Datos Empresa',
                                            description: 'Administrador'
                                        },
                                        {
                                            // title: 'Datos Representantes',
                                            title: <span>Datos Representantes</span>,
                                            description: 'Representante legal'
                                        },
                                        {
                                            title: <span>Datos finales</span>,
                                            // title: 'Datos finales',
                                            description: 'Facturación'
                                        },
                                    ]}
                                />
                                <br />
                                <Form
                                    initialValues={{ representantesLegales: [{}] }}
                                    autoComplete="off"
                                    form={form}
                                    onFinish={onFinish}
                                    style={{ width: '100%' }}
                                    className="form "
                                    layout="vertical">
                                    {/* se ocultan pasos segun paso Actual, si se valida mediante if, no llegan los valores del form */}
                                    <Paso1 rubrosComerciales={rubrosComerciales} cargos={cargos} areaCargos={areaCargos} setPasoActual={setPasoActual} form={form} pasoActual={pasoActual}></Paso1>
                                    <Paso2 cargos={cargos} areaCargos={areaCargos} nacionalidades={nacionalidades} paises={paises} setPasoActual={setPasoActual} form={form} pasoActual={pasoActual}></Paso2>
                                    <Paso3 isSubmiting={formIsSubmitting} cargos={cargos} areaCargos={areaCargos} paises={paises} setPasoActual={setPasoActual} form={form} pasoActual={pasoActual}></Paso3>
                                </Form>
                                <br></br>
                            </div>
                        </Col>
                        <Col xs={0} sm={0} md={0} lg={7}>
                            <div className="right-section" style={{ minHeight: 'auto' }}>
                                <div className="background-image"></div>
                            </div>
                        </Col>
                    </Row>
                </Content>
            </Layout>
            <ModalAvisos fueCorrecto={accionCorrecta} isModalOpen={isModalOpen} onClose={handleOk} mensaje={accionCorrecta ? 'Se han completado los datos de la empresa correctamente. Te enviamos un correo con información de los próximos pasos.' : 'Ha ocurrido un error, inténtelo nuevamente'} titulo={accionCorrecta ? 'Datos completados exitosamente' : 'Error'} />
        </>
    )
}

export default FormCompletarCuentaEmpresa