import React from 'react'
import Title from 'antd/es/typography/Title'
import locale from 'antd/es/date-picker/locale/es_ES'
import { Divider, Space } from 'antd';
import { UsergroupAddOutlined, UsergroupDeleteOutlined } from '@ant-design/icons';
import { Button, Select, Row, Col, Form, Input, DatePicker } from 'antd';
import { esRutValido } from '../../../services/Helpers/RutHelper';

const Paso2 = ({ setPasoActual, form, pasoActual, cargos, areaCargos, nacionalidades, paises }) => {
    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

    const onNextPage = async () => {
        form.validateFields(['representantesLegales'], { validateOnly: false, recursive: true })
            .then((values) => {
                setPasoActual(2)
            }).catch((errorInfo) => {
                console.log(errorInfo)
            })
    }

    const onValidateRut = async (value) => {
        const esValido = esRutValido(value)

        if (esValido) {
            return Promise.resolve()
        }
        else {
            return Promise.reject(new Error('Ingresa un Rut válido'))
        }
    }

    return (
        <div style={{ display: `${pasoActual === 1 ? '' : 'none'}` }}>
            <br></br>
            <Form.List name="representantesLegales">
                {(fields, { add, remove }) => (
                    <div                    >
                        {fields.map((field, index) => (
                            <div key={index}>
                                {index > 0 ? <Divider></Divider> : null}
                                <Row gutter={{ xs: 4, sm: 24, md: 5, lg: 10 }} style={{ textAlign: 'left' }}>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={24}>
                                        <Space>
                                            <Title level={4} style={{ fontWeight: 'bold', marginTop: '15px', marginBottom: '15px' }}>
                                                Datos Representante legal {index > 0 ? `#${index + 1}` : ''}
                                            </Title>
                                            {fields.length > 1 ? <Button onClick={() => remove(field.name)} className="btmEliminaRepresentane" style={{ paddingBottom: 0 }} icon={<UsergroupDeleteOutlined />} type="link">Eliminar representante legal</Button> : null}
                                        </Space>
                                    </Col>
                                </Row>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                                        <Form.Item
                                            name={[field.name, 'nombreRepresentante']}
                                            label='Nombres Representante'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Ingresa nombre",
                                                },
                                            ]}
                                        >
                                            <Input placeholder='Ingresa nombres' />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                                        <Form.Item
                                            name={[field.name, 'apellidosRepresentante']}
                                            label='Apellidos Representante'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Ingresa apellidos",
                                                },
                                            ]}
                                        >
                                            <Input placeholder='Ingresa apellidos' />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                                        <Form.Item
                                            name={[field.name, 'rut']}
                                            label='Número de identificación (RUT)'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Ingresa número de identificación",
                                                },
                                                {
                                                    validator: (_, value) => value ? onValidateRut(value) : Promise.resolve()
                                                }
                                            ]}
                                        >
                                            <Input placeholder='11.111.111-1' />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                                        <Form.Item
                                            name={[field.name, 'nacionalidadId']}
                                            label="Nacionalidad"
                                            className="username"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Ingresa una nacionalidad",
                                                },
                                            ]}>
                                            <Select
                                                showSearch
                                                filterOption={filterOption}
                                                placeholder="Selecciona una nacionalidad"
                                                options={nacionalidades}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                                        <Form.Item
                                            name={[field.name, 'paisResidenciaId']}
                                            label="País de residencia"
                                            className="username"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Ingresa país de residencia",
                                                },
                                            ]}>
                                            <Select
                                                showSearch
                                                filterOption={filterOption}
                                                placeholder="Selecciona país de residencia"
                                                options={paises}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                                        <Form.Item
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Ingresa Fecha de nacimiento",
                                                }]}
                                            name={[field.name, 'fechaNacimientoRep']}
                                            label='Fecha de nacimiento'
                                            className="username">
                                            <DatePicker locale={locale} format={"DD/MM/YYYY"} placeholder='Selecciona fecha' style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                                        <Form.Item name={[field.name, 'cargoId']} label="Cargo" className="username">
                                            <Select
                                                showSearch
                                                filterOption={filterOption}
                                                placeholder="Selecciona cargo"
                                                options={cargos}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                                        <Form.Item name={[field.name, 'areaCargoId']} label="Área del cargo" className="username">
                                            <Select
                                                showSearch
                                                filterOption={filterOption}
                                                placeholder="Selecciona área de cargo"
                                                options={areaCargos}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                                        <Form.Item name={[field.name, 'email']} label='Correo electrónico' className="username" rules={[
                                            {
                                                required: true,
                                                message: "Ingresa un correo electrónico",
                                            },
                                            {
                                                type: "email",
                                                message: "Ingresa un email válido",
                                            }
                                        ]}>
                                            <Input placeholder='correo@correo.cl' />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                        ))}
                        <Divider orientation="right" orientationMargin={0}>

                            <Button
                                icon={<UsergroupAddOutlined />}
                                type="primary"
                                className="btnAzul"
                                htmlType="button"
                                onClick={() => add()}
                                style={{ paddingRight: 15, paddingLeft: 15 }}
                                shape="round"
                            >
                                Agregar representante legal
                            </Button>
                        </Divider>
                    </div>
                )}
            </Form.List>
            <br />
            <Row justify="center" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col xs={12} sm={7} md={6} lg={5} xl={5} xxl={4}>
                    <Button
                        htmlType="default"
                        className="btnVolver"
                        htmlType="button"
                        style={{ paddingRight: 40, paddingLeft: 40 }}
                        shape="round"
                        onClick={() => setPasoActual(0)}
                    >
                        Volver
                    </Button>
                </Col>
                <Col xs={12} sm={7} md={6} lg={5} xl={5} xxl={4}>
                    <Button
                        type="primary"
                        htmlType="button"
                        className="btnAzul"
                        onClick={() => onNextPage()}
                        style={{ paddingRight: 40, paddingLeft: 40 }}
                        shape="round"
                    >
                        Siguiente
                    </Button>
                </Col>
            </Row>
        </div>
    )
}

export default Paso2