import { useState } from 'react'
import { Row, Col, Button, Form, Input, Select } from 'antd'
import Title from 'antd/es/typography/Title'
import { getComunas, getRegiones } from '../../../services/publicApi'
import { FileSearchOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { CapitalizeFirstLetter } from '../../../services/Helpers/stringHelper'
const dataOpcionesHes = [{ value: 1, label: 'Sí' }, { value: 0, label: 'No' }]

const Paso3 = ({ setPasoActual, form, pasoActual, cargos, areaCargos, paises, isSubmiting }) => {
    const [regiones, setRegiones] = useState(null)
    const [comunas, setComunas] = useState(null)
    const [nameDropIsLoading, setNameDropIsLoading] = useState('')
    const usuario = useSelector(state => state.aplicacion.usuario)
    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
    const valueHojaHes = Form.useWatch(['datosFacturacion', 'solicitaHes'], form)

    const onAutoCompletarDatos = async () => {
        onChangePais(usuario.paisId, false)
        onChangeRegion(usuario.regionId, false)
        onChangeAreaCargo(form.getFieldValue(['informacionExtraAdministrador', 'areaCargoId']))

        form.setFieldValue(['datosFacturacion', 'nombre'], CapitalizeFirstLetter(usuario.nombres))
        form.setFieldValue(['datosFacturacion', 'apellidos'], CapitalizeFirstLetter(usuario.apellidos))
        form.setFieldValue(['datosFacturacion', 'cargoId'], form.getFieldValue(['informacionExtraAdministrador', 'cargoId']))
        form.setFieldValue(['datosFacturacion', 'areaCargoId'], form.getFieldValue(['informacionExtraAdministrador', 'areaCargoId']))
        form.setFieldValue(['datosFacturacion', 'telefono'], usuario.celular)
        form.setFieldValue(['datosFacturacion', 'direccionFacturacion'], form.getFieldValue('direccion'))
        form.setFieldValue(['datosFacturacion', 'comunaId'], usuario.comunaId)
        form.setFieldValue(['datosFacturacion', 'region'], usuario.regionId)
        form.setFieldValue(['datosFacturacion', 'paisId'], usuario.paisId)
        form.setFieldValue(['datosFacturacion', 'emailFacturacion'], usuario.email)
        form.setFieldValue(['datosFacturacion', 'glosaPais'], usuario.pais)

        const glosaAreaCargo = areaCargos.filter(x => x.value == form.getFieldValue(['informacionExtraAdministrador', 'areaCargoId']))[0]?.label
        form.setFieldValue(['datosFacturacion', 'glosaAreaCargo'], glosaAreaCargo)

        form.validateFields(['datosFacturacion'], { validateOnly: false, recursive: true }).then((values) => { }).catch(() => { })
    }

    const onChangeRegion = async (value, clearComuna) => {
        try {
            setNameDropIsLoading('drpComunas')

            if (clearComuna)
                form.setFieldValue(['datosFacturacion', 'comunaId'], null)

            const result = await getComunas(value)
            setComunas(result)
        }
        catch {
        }
        finally {
            setNameDropIsLoading('')
        }
    }

    const onChangeAreaCargo = (value) => {
        const glosa = areaCargos.filter(x => x.value === value)[0]?.label
        form.setFieldValue(['datosFacturacion', 'glosaAreaCargo'], glosa)
    }

    const onChangePais = async (value, clearRegion) => {
        try {
            setNameDropIsLoading('drpRegiones')

            if (clearRegion)
                form.setFieldValue(['datosFacturacion', 'regionId'], null)

            const glosa = paises.filter(x => x.value === value)[0]?.label
            form.setFieldValue(['datosFacturacion', 'glosaPais'], glosa)

            const result = await getRegiones(value)
            setRegiones(result)
        }
        catch {
        }
        finally {
            setNameDropIsLoading('')
        }
    }

    return (
        <div style={{ display: `${pasoActual === 2 ? '' : 'none'}` }}>
            <br />
            <Form.Item style={{ display: 'none' }} name={['datosFacturacion', 'glosaPais']}><Input /></Form.Item>
            <Form.Item style={{ display: 'none' }} name={['datosFacturacion', 'glosaAreaCargo']}><Input /></Form.Item>

            <Row gutter={{ xs: 4, sm: 24, md: 5, lg: 10 }} style={{ textAlign: 'left' }}>
                <Col className="gutter-row" xs={24} sm={24} md={24} lg={24}>
                    <Title level={4} style={{ fontWeight: 'bold', marginTop: 0, marginBottom: '15px' }}>
                        Datos de facturación
                    </Title>
                </Col>
            </Row>
            <Row style={{ textAlign: 'left' }} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <Button
                        icon={<FileSearchOutlined />}
                        type="primary"
                        className="btnAzul"
                        htmlType="button"
                        onClick={() => onAutoCompletarDatos()}
                        style={{ paddingRight: 20, paddingLeft: 20, marginBottom: 25 }}
                        shape="round"
                    >
                        Usar datos anteriores
                    </Button>
                </Col>
                <br />
            </Row>

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                    <Form.Item
                        name={['datosFacturacion', 'nombre']}
                        label='Nombres'
                        className="username"
                        rules={[
                            {
                                required: true,
                                message: "Ingresa tus nombres",
                            },
                        ]}
                    >
                        <Input placeholder='Ingresa nombres' autoComplete="off" />
                    </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                    <Form.Item
                        name={['datosFacturacion', 'apellidos']}
                        label='Apellidos'
                        className="username"
                        rules={[
                            {
                                required: true,
                                message: "Ingresa tus apellidos",
                            },
                        ]}
                    >
                        <Input placeholder='Ingresa apellidos' autoComplete="off" />
                    </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                    <Form.Item
                        name={['datosFacturacion', 'telefono']}
                        label='Teléfono fijo o Celular'
                        className="username"
                        rules={[
                            {
                                required: true,
                                message: "Ingresa un teléfono",
                            },
                        ]}
                    >
                        <Input placeholder='+56912332155' autoComplete="off" />
                    </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                    <Form.Item name={['datosFacturacion', 'cargoId']} label="Cargo" className="username">
                        <Select
                            showSearch
                            filterOption={filterOption}
                            placeholder="Selecciona un cargo"
                            options={cargos}
                        />
                    </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                    <Form.Item name={['datosFacturacion', 'areaCargoId']} label="Área del cargo" className="username">
                        <Select
                            showSearch
                            filterOption={filterOption}
                            placeholder="Selecciona área de cargo"
                            options={areaCargos}
                            onChange={value => onChangeAreaCargo(value, true)}
                        />
                    </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                    <Form.Item name={['datosFacturacion', 'paisId']} label='País' className="username" rules={[
                        {
                            required: true,
                            message: "Ingresa tu país",
                        },
                    ]}>
                        <Select
                            showSearch
                            onChange={value => onChangePais(value, true)}
                            filterOption={filterOption}
                            placeholder="Selecciona un país"
                            options={paises}
                        />
                    </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                    <Form.Item name={['datosFacturacion', 'region']} label='Región' rules={[
                        {
                            required: true,
                            message: "Selecciona tu región",
                        },
                    ]}>
                        <Select
                            showSearch
                            onChange={value => onChangeRegion(value, true)}
                            placeholder="Selecciona una región"
                            filterOption={filterOption}
                            options={regiones}
                            loading={nameDropIsLoading === 'drpRegiones'}
                        />
                    </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                    <Form.Item name={['datosFacturacion', 'comunaId']} label='Comuna'
                        rules={[
                            {
                                required: true,
                                message: "Selecciona tu Comuna",
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Selecciona una comuna"
                            filterOption={filterOption}
                            options={comunas}
                            loading={nameDropIsLoading === 'drpComunas'}
                        />
                    </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                    <Form.Item name={['datosFacturacion', 'direccionFacturacion']} label='Dirección' className="username" rules={[
                        {
                            required: true,
                            message: "Ingresa una dirección",
                        },
                    ]}>
                        <Input maxLength="150" autoComplete="off" placeholder='Ingresa una dirección' />
                    </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                    <Form.Item name={['datosFacturacion', 'emailFacturacion']} label='Correo de facturación' className="username" rules={[
                        {
                            required: true,
                            message: "Ingresa un correo electrónico",
                        },
                        {
                            type: "email",
                            message: "Ingresa un email válido",
                        }
                    ]}>
                        <Input autoComplete="off" placeholder='correo@correo.cl' />
                    </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                    <Form.Item style={{ marginBottom: 0, paddingBotton: 0 }} name={['datosFacturacion', 'solicitaHes']} label='Hoja de entrada servicios (HES/OC)' rules={[
                        {
                            required: true,
                            message: "Selecciona Hoja de entrada",
                        },
                    ]}>
                        <Select
                            showSearch
                            placeholder="Selecciona una entrada"
                            filterOption={filterOption}
                            options={dataOpcionesHes}
                        />
                    </Form.Item>
                    {valueHojaHes === 1 ?
                        <div className="ant-form-item-explain ant-form-item-explain-connected css-dev-only-do-not-override-amq5gd" role="alert">
                            <div style={{ color: '#0F00D8' }} className="ant-form-item-explain-error">Cuando corresponda, envía tu documento HES u OC al siguiente correo sacbursatil@bolsadesantiago.com</div>
                            <br />
                        </div> : null}
                </Col>
            </Row>
            <Row justify="center" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col xs={12} sm={7} md={6} lg={5} xl={5} xxl={4}>
                    <Button
                        className="btnVolver"
                        htmlType="button"
                        style={{ paddingRight: 40, paddingLeft: 40 }}
                        shape="round"
                        onClick={() => setPasoActual(1)}
                    >
                        Volver
                    </Button>
                </Col>
                <Col xs={12} sm={7} md={6} lg={5} xl={5} xxl={4}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="btnAzul"
                        style={{ paddingRight: 40, paddingLeft: 40 }}
                        shape="round"
                        loading={isSubmiting}
                    >
                        Finalizar
                    </Button>
                </Col>
            </Row>
        </div>
    )
}

export default Paso3