import React from 'react'
import { Row, Col, Button, Form, Input, Select, Upload, message } from 'antd'
import Title from 'antd/es/typography/Title'
import { useKeycloak } from '@react-keycloak/web'
import { UploadOutlined } from '@ant-design/icons'

const extensionesPermitidas = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf']

const Paso1 = ({ setPasoActual, form, pasoActual, rubrosComerciales, cargos, areaCargos }) => {
    const { keycloak } = useKeycloak()
    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

    const onNextPage = async () => {
        form.validateFields(['direccion', 'rubroComercialId', 'documentoTributario'])
            .then((values) => {
                setPasoActual(1)
            }).catch((errorInfo) => { })
    }

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    const onChangeAreaCargo = (value) => {
        const glosa = areaCargos.filter(x => x.value === value)[0].label
        form.setFieldValue(['informacionExtraAdministrador', 'glosaAreaCargo'], glosa)
    }

    const beforeUpload = (file) => {
        const esValido = extensionesPermitidas.some(x => x === file.type)

        if (esValido) {
            return true
        }
        else {
            message.error('Sólo se permiten archivos PDF e imágenes');
            return Upload.LIST_IGNORE;
        }
    }

    return (
        <div style={{ display: `${pasoActual === 0 ? '' : 'none'}` }}>
            <Row gutter={{ xs: 4, sm: 24, md: 5, lg: 10 }} style={{ textAlign: 'left' }}>
                <Col className="gutter-row" xs={24} sm={24} md={24} lg={24}>
                    <Title level={4} style={{ fontWeight: 'bold', marginTop: '15px', marginBottom: '15px' }}>
                        Datos Empresa
                    </Title>
                </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                    <Form.Item name='rubroComercialId' label="Rubro comercial" className="username"
                        rules={[
                            {
                                required: true,
                                message: "Ingresa un rubro comercial",
                            },
                        ]}>
                        <Select
                            showSearch
                            filterOption={filterOption}
                            placeholder="Selecciona un rubro"
                            options={rubrosComerciales}
                            loading={rubrosComerciales === null}
                        />
                    </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                    <Form.Item
                        name='direccion'
                        label='Dirección'
                        rules={[
                            {
                                required: true,
                                message: "Ingresa dirección de la empresa",
                            },
                        ]}
                    >
                        <Input placeholder=' Ingresa una dirección' autoComplete="off" />
                    </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                    <Form.Item
                        style={{ textAlign: 'left' }}
                        name="documentoTributario"
                        label="e-RUT Empresa"
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                        rules={[
                            {
                                required: true,
                                message: "Adjunta documento e-RUT",
                            }
                        ]}
                    >
                        <Upload accept={extensionesPermitidas.join(',')} name="logo"
                            beforeUpload={beforeUpload}
                            maxCount={1}
                            customRequest={({ file, onSuccess }) => {
                                return onSuccess()
                            }}
                            listType="text">
                            <Button className="upload-button" icon={<UploadOutlined />}>Haga clic para subir documento</Button>
                        </Upload>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={{ xs: 4, sm: 24, md: 5, lg: 10 }} style={{ textAlign: 'left' }}>
                <Col className="gutter-row" xs={24} sm={24} md={24} lg={24}>
                    <Title level={4} style={{ fontWeight: 'bold', marginTop: '15px', marginBottom: '15px' }}>
                        Datos Usuario administrador
                    </Title>
                </Col>
            </Row>
            <Form.Item style={{ display: 'none' }} name={['informacionExtraAdministrador', 'glosaAreaCargo']}><Input /></Form.Item>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                    <Form.Item name={['informacionExtraAdministrador', 'cargoId']}
                        label="Cargo" className="username">
                        <Select
                            showSearch
                            filterOption={filterOption}
                            placeholder="Selecciona tu cargo"
                            options={cargos}
                            loading={cargos === null}
                        />
                    </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                    <Form.Item name={['informacionExtraAdministrador', 'areaCargoId']}
                        label="Área del cargo"
                        className="username">
                        <Select
                            showSearch
                            filterOption={filterOption}
                            placeholder="Selecciona tu área de cargo"
                            options={areaCargos}
                            loading={areaCargos === null}
                            onChange={value => onChangeAreaCargo(value, true)}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <br />
            <Row justify="center" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col xs={12} sm={7} md={6} lg={5} xl={5} xxl={4}>
                    <Button
                        htmlType="default"
                        className="btnVolver"
                        htmlType="button"
                        style={{ paddingRight: 40, paddingLeft: 40 }}
                        shape="round"
                        onClick={() => keycloak.logout({ redirectUri: process.env.REACT_APP_URL_ONBOARDING_PUBLICO })}
                    >
                        Volver
                    </Button>
                </Col>
                <Col xs={12} sm={7} md={6} lg={5} xl={5} xxl={4}>
                    <Button
                        style={{ paddingRight: 40, paddingLeft: 40 }}
                        type="primary"
                        className="btnAzul"
                        htmlType="button"
                        onClick={() => onNextPage()}
                        shape="round"
                    >
                        Siguiente
                    </Button>
                </Col>
            </Row>
        </div>
    )
}

export default Paso1