import { Avatar, Button, Checkbox, Col, DatePicker, Form, Input, Layout, Row, Select, Space } from 'antd'
import { Content } from 'antd/es/layout/layout'
import Link from 'antd/es/typography/Link'
import './FormCrearCuentaPersonas.styles.scss'
import Title from 'antd/es/typography/Title'
import { UserOutlined, ExclamationCircleOutlined, LeftCircleFilled } from '@ant-design/icons'
import ModalExitoCrearCuenta from '../modal/ModalExitoCrearCuenta'
import { useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web'
import { useSelector } from 'react-redux'
import { existeEmail, getPaises, existeRutRegistrado, getCargos, getCiudades, getAreaCargos, getRegiones, getComunas, getNacionalidades, savePersonaNatural } from '../../services/publicApi'
import { esRutValido, getRutSinDv, getDvRut, cleanRut, daFormatoRut } from '../../services/Helpers/RutHelper'
import { clearNumeroTelefono } from '../../services/Helpers/stringHelper'
import { useNavigate } from 'react-router'
import Loader from '../Loader/loader';
import { getTerminosCondicionesPortal } from '../../services/documentoApi'

const patternPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%#*?&\-_\.])[A-Za-z\d@$!#%*?&\-_\.]{12,}$/;

const FormCrearCuentaPersonas = () => {
    const { keycloak } = useKeycloak()
    const declaracionCompliance = useSelector(store => store.aplicacion.declaracionCompliance)
    const navigate = useNavigate()
    const [form] = Form.useForm();
    const [accionCorrecta, setAccionCorrecta] = useState(true)
    const [existeEmailBd, setExisteEmail] = useState(null)
    const [isDownloadDocumentacion, setIsDownloadDocumentacion] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [paises, setPaises] = useState([{ label: 'Chile', value: 'CL' }])
    const [nacionalidades, setNacionalidades] = useState(null)
    const [cargos, setCargos] = useState(null)
    const [areaCargos, setAreaCargos] = useState(null)
    const [regiones, setRegiones] = useState(null)
    const [comunas, setComunas] = useState(null)
    const [formIsSubmiting, setFormIsSubmiting] = useState(false)
    const [nameDropIsLoading, setNameDropIsLoading] = useState('')
    const valuePassword = Form.useWatch('password', form)
    const valuePasswordRepeat = Form.useWatch('passwordRepeat', form)

    useEffect(() => {
        const fetchData = async () => {
            Promise.all([
                // getPaises(),
                getNacionalidades(),
                getCargos(),
                getAreaCargos()]).then((values) => {
                    // setPaises(values[0])
                    setNacionalidades(values[0])
                    setCargos(values[1])
                    setAreaCargos(values[2])
                }).finally(() =>
                    console.log()
                );
        }
        fetchData()
    }, [])

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const onChangeRegion = async (value) => {
        try {
            setNameDropIsLoading('drpComunas')
            form.setFieldValue('comunaId', null)
            const result = await getComunas(value)
            setComunas(result)
        }
        catch {
        }
        finally {
            setNameDropIsLoading('')
        }
    }

    const onChangePais = async (value) => {
        try {
            setNameDropIsLoading('drpRegiones')
            const glosa = paises.filter(x => x.value === value)[0].label
            form.setFieldValue('regionId', null)
            form.setFieldValue('glosaPais', glosa)

            const result = await getRegiones(value)
            setRegiones(result)
        }
        catch {
        }
        finally {
            setNameDropIsLoading('')
        }
    }

    const onChangeAreaCargo = (value) => {
        const glosa = areaCargos.filter(x => x.value === value)[0].label
        form.setFieldValue('glosaAreaCargo', glosa)
    }

    const onFinish = async (values) => {
        try {
            setFormIsSubmiting(true)
            values.DVnumeroIdentificacion = getDvRut(values.numeroIdentificacion)
            values.numeroIdentificacion = getRutSinDv(values.numeroIdentificacion)
            values.fechaNacimiento = values.fechaNacimiento ? values.fechaNacimiento.toDate() : null
            values.esPep = declaracionCompliance.esPep
            values.idTiempoPep = declaracionCompliance.tiempoPep

            const result = await savePersonaNatural(values)

            if (result.accionFueCorrecta) {
                setAccionCorrecta(true)
            }
            else {
                setAccionCorrecta(false)
                throw new Error('error');
            }
        }
        catch (error) {
            setAccionCorrecta(false)
        }
        finally {
            setIsModalOpen(true)
            setFormIsSubmiting(false)
        }
    };

    const onValidateEmail = async (value) => {
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+\.)+[a-zA-Z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]{2,}))$/
        const result = regex.test(value);

        if (result) {
            const data = await existeEmail(value)
            setExisteEmail(data)
            setFormIsSubmiting(false)

            if (data.tieneCuentaOnBoarding) {
                return Promise.reject(new Error('Email ingresado ya se encuentra registrado'))
            }
            else {
                return Promise.resolve()
            }
        }
    }

    const onValidateRut = async (value) => {
        const esValido = esRutValido(value)
        const existe = esValido ? await existeRutRegistrado(cleanRut(value)) : false
        setFormIsSubmiting(false)

        if (esValido && !existe) {
            return Promise.resolve()
        }
        else if (!esValido) {
            return Promise.reject(new Error('Ingresa un Rut válido'))
        }
        else if (existe) {
            return Promise.reject(new Error('Este RUT ya tiene una cuenta creada'))
        }
    }

    const onValidatePassword = async (value) => {
        const passwordValue = form.getFieldValue('password')

        if (passwordValue !== value)
            return Promise.reject(new Error('Las contraseñas no coindicen'))
        else
            return Promise.resolve()
    }

    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const onVolver = () => {
        navigate('/')
        window.scrollTo(0, 0)
    }

    const onDownloadTerminosYcondiciones = async () => {
        setIsDownloadDocumentacion(true)
        try {
            const docRes = await getTerminosCondicionesPortal()

            if (docRes) {
                const blob = new Blob([docRes.buffer], { type: 'application/pdf' });
                const linkRedirect = URL.createObjectURL(blob);

                window.open(linkRedirect, "_blank");
            } else {
                console.log("Respuesta nula")
            }
        } catch (error) {
            console.error("Error al descargar documento")
            console.error(error)
        } finally {
            setIsDownloadDocumentacion(false)
        }
    }

    const onCrearCuenta = () => {
        setFormIsSubmiting(true)
        form.submit()
    }

    return (
        <>
            <Loader isLoadingExt={isDownloadDocumentacion}></Loader>
            <Layout className="app-layout">
                <Content className="content">
                    <Row gutter={{ xs: 0, sm: 0, md: 0, lg: 0 }}>
                        <Col xs={24} sm={24} md={24} lg={0} style={{ minHeight: '70px', paddingTop: 20, backgroundColor: '#EDF6FF', textAlign: 'left' }}>
                            <Space>
                                <LeftCircleFilled onClick={() => navigate(-1)} style={{ fontSize: 30, marginLeft: 30, color: '#ff4201' }} />
                                <p onClick={() => navigate(-1)} style={{ marginTop: 10 }}><strong> Volver atrás</strong></p>
                            </Space>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={14} style={{ minHeight: '100vh' }} className='containerFormPersona'>
                            <div className="header-container">
                                <Row style={{ textAlign: 'left' }} gutter={{ xs: 4, sm: 0, md: 5, lg: 10 }}>

                                    {/* mobile */}
                                    <Col className="gutter-row" xs={3} sm={2} md={2} lg={0}>
                                        <Avatar style={{ backgroundColor: '#00FF91', color: 'black' }} className="avatar" size={50} icon={<UserOutlined />} />
                                    </Col>
                                    <Col className="gutter-row" xs={21} sm={14} md={10} lg={0}>
                                        <Title style={{ fontWeight: 'bold' }} level={4} className='title' >Regístrate en Portal Persona</Title>
                                    </Col>
                                    <Row gutter={{ xs: 4, sm: 24, md: 5, lg: 10 }}>
                                        <Col className="gutter-row" xs={2} sm={2} md={2} lg={0}>
                                            <Avatar size={45} style={{ backgroundColor: 'white', color: '#F1D779' }} className="avatar" icon={<ExclamationCircleOutlined />} />
                                        </Col>
                                        <Col className="gutter-row" xs={22} sm={22} md={22} lg={0}>
                                            <p className='subtitle' style={{ marginBottom: 0 }}>Completa la información solicitada para crear una cuenta.</p>
                                        </Col>
                                        <Col className="gutter-row" xs={2} sm={2} md={2} lg={0}>
                                            <Avatar size={45} style={{ backgroundColor: 'white', color: '#F1D779', marginTop: 5 }} className="avatar" icon={<ExclamationCircleOutlined />} />
                                        </Col>
                                        <Col className="gutter-row" xs={22} sm={22} md={22} lg={0}>
                                            <p className='subtitle' style={{ marginTop: 10 }}> Por el momento solo se pueden crear una cuenta personas que tengan RUT.</p>
                                        </Col>
                                    </Row>
                                    {/* desktop */}
                                    <Col className="gutter-row" xs={0} sm={0} md={0} lg={3} xl={3} xxl={2}>
                                        <Avatar style={{ color: 'black' }} className="avatar-desktop" size={80} icon={<UserOutlined />} />
                                    </Col>
                                    <Col className="titulo" xs={0} sm={0} md={0} lg={21} xl={20} xxl={21} >
                                        <Row gutter={{ xs: 5, sm: 5, md: 5, lg: 5 }}>
                                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={24}>
                                                <Title level={2} style={{ marginTop: '0px', marginBottom: '0px', fontWeight: 'bold' }}>Regístrate en Portal Persona</Title>
                                            </Col>
                                        </Row>
                                        <Row gutter={{ xs: 5, sm: 5, md: 5, lg: 5 }}>
                                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={24}>
                                                <Title level={5} style={{ marginTop: '5px' }}>
                                                    Completa la información solicitada para crear una cuenta
                                                </Title>
                                            </Col>
                                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={24}>
                                                <p style={{ marginTop: 0, fontSize: 12 }}>
                                                    Por el momento solo se pueden crear una cuenta personas que tengan RUT.
                                                </p>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>

                            <Form form={form} onFinish={onFinish} className="form formCrear" layout="vertical">
                                <Form.Item style={{ display: 'none' }} name='glosaPais'><Input /></Form.Item>
                                <Form.Item style={{ display: 'none' }} name='glosaAreaCargo'><Input /></Form.Item>

                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                                        <Form.Item
                                            name='nombre'
                                            label='Nombres'
                                            className="username"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Ingresa tus nombres",
                                                },
                                            ]}
                                        >
                                            <Input placeholder='Andrés Nicolás' />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                                        <Form.Item
                                            label='Apellidos'
                                            name='apellidos'
                                            className="username"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Ingresa tus apellidos",
                                                },
                                            ]}
                                        >
                                            <Input placeholder='Pérez Sánchez' />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                                        <Form.Item
                                            name='numeroIdentificacion'
                                            label='Número de Identificación (RUT)'
                                            normalize={(value) => daFormatoRut(value)}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Ingresa tu número de identificación",
                                                },
                                                {
                                                    validator: (_, value) => value ? onValidateRut(value) : Promise.resolve()
                                                }
                                            ]}
                                        >
                                            <Input placeholder='12.345.678-9' />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                                        <Form.Item name='fechaNacimiento' label='Fecha de nacimiento' className="username"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Ingresa tu fecha de nacimiento",
                                                },
                                            ]}
                                        >
                                            <DatePicker format={"DD/MM/YYYY"} placeholder='Selecciona fecha' style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                                        <Form.Item name='nacionalidadId' label="Nacionalidad" className="username"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Ingresa tu nacionalidad",
                                                },
                                            ]}>
                                            <Select
                                                showSearch={false}
                                                filterOption={filterOption}
                                                placeholder="Chilena"
                                                options={nacionalidades}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                                        <Form.Item name='paisId' label='País' className="username" rules={[
                                            {
                                                required: true,
                                                message: "Ingresa tu país",
                                            },
                                        ]}>
                                            <Select
                                                showSearch={false}
                                                onChange={onChangePais}
                                                filterOption={filterOption}
                                                placeholder="Chile"
                                                options={paises}
                                                loading={paises === null}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                                        <Form.Item name='regionId' label='Región' rules={[
                                            {
                                                required: true,
                                                message: "Selecciona tu región",
                                            },
                                        ]}>
                                            <Select
                                                notFoundContent={nameDropIsLoading === 'drpRegiones' ? 'Cargando...' : 'Debes seleccionar primero un País.'}
                                                showSearch={false}
                                                onChange={onChangeRegion}
                                                loading={nameDropIsLoading === 'drpRegiones'}
                                                placeholder="Metropolitana"
                                                filterOption={filterOption}
                                                options={regiones}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                                        <Form.Item name='comunaId' label='Ciudad'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Selecciona tu ciudad",
                                                },
                                            ]}
                                        >
                                            <Select
                                                notFoundContent={nameDropIsLoading === 'drpComunas' ? 'Cargando...' : 'Debes seleccionar primero una Región.'}
                                                showSearch={false}
                                                loading={nameDropIsLoading === 'drpComunas'}
                                                placeholder="Santiago"
                                                filterOption={filterOption}
                                                options={comunas}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                                        <Form.Item name='direccion' label='Dirección' className="username" rules={[
                                            {
                                                required: true,
                                                message: "Ingresa tu dirección",
                                            },
                                        ]}>
                                            <Input maxLength="150" placeholder='Calle principal n° 181' />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                                        <Form.Item name='telefono'
                                            normalize={(value) => clearNumeroTelefono(value)}
                                            label='Teléfono fijo o Celular' className="username" rules={[
                                                {
                                                    required: true,
                                                    message: "Ingresa tu teléfono o celular",
                                                },
                                            ]}>
                                            <Input placeholder='+569 12332155' />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                                        <Form.Item autoComplete="off" name='email' label='Correo electrónico' className="username" rules={[
                                            {
                                                required: true,
                                                message: "Ingresa tu correo electrónico",
                                            },
                                            {
                                                type: "email",
                                                message: "Ingresa un email válido",
                                            },
                                            {
                                                validator: (_, value) => value ? onValidateEmail(value) : Promise.resolve()
                                            }
                                        ]}>
                                            <Input autoComplete="off" placeholder='correo@correo.cl' />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                                        <Form.Item name='profesionUOficio' label='Profesión u Oficio' className="username" rules={[
                                            {
                                                required: true,
                                                message: "Ingresa tu profesión u oficio",
                                            },
                                        ]}>
                                            <Input placeholder='Diseñador' />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                                        <Form.Item label='Cargo' name="cargoId">
                                            <Select
                                                showSearch={false}
                                                placeholder="Analista"
                                                optionFilterProp="children"
                                                options={cargos}
                                                loading={cargos === null}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                                        <Form.Item label='Área del cargo' name="areaCargoId">
                                            <Select
                                                showSearch={false}
                                                placeholder="Área de diseño"
                                                optionFilterProp="children"
                                                options={areaCargos}
                                                loading={areaCargos === null}
                                                onChange={onChangeAreaCargo}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                {!existeEmailBd?.tieneCuentakeyCloack ?
                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                        <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                                            <Form.Item name='password' label='Crear contraseña' className="username" rules={[
                                                {
                                                    required: true,
                                                    message: "Ingresa una contraseña segura",
                                                },
                                                {
                                                    pattern: patternPassword,
                                                    message: <ul style={{ textAlign: 'left', paddingLeft: '17px' }}>
                                                        <li>Debe ingresar una contraseña de al menos 12 dígitos.</li>
                                                        <li>La contraseña debe tener al menos una minúscula y una mayúscula.</li>
                                                        <li>La contraseña debe tener al menos un número.</li>
                                                        <li>La contraseña debe tener al menos un símbolo especial [!@#$%&*-_.].</li>
                                                    </ul>
                                                }
                                            ]}>
                                                <Input.Password className={new RegExp(patternPassword).test(valuePassword) ? 'sucessValidation' : ''} placeholder='Ingresa una contraseña' />
                                            </Form.Item>
                                        </Col>
                                        <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                                            <Form.Item onPaste={e => {
                                                e.preventDefault();
                                                return false
                                            }} autoComplete="off" name='passwordRepeat' label='Repetir contraseña' className="username" rules={[
                                                {
                                                    required: true,
                                                    message: "Ingresa una contraseña segura",
                                                },
                                                {
                                                    validator: (_, value) => value ? onValidatePassword(value) : Promise.resolve()
                                                }
                                            ]}>
                                                <Input.Password className={valuePassword === valuePasswordRepeat ? 'sucessValidation' : ''} placeholder='Vuelve a escribir la contraseña' />
                                            </Form.Item>
                                        </Col>
                                    </Row> : null}
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col style={{ textAlign: 'left', marginTop: 20 }} className="gutter-row" xs={24} sm={24} md={24} lg={24}>
                                        <Form.Item normalize={(value) => value ? value : undefined} name='terminos' valuePropName="checked" rules={[
                                            {
                                                required: true,
                                                message: "Debes aceptar los Términos y Condiciones y la Política de Privacidad para poder continuar.",
                                            },
                                        ]}>
                                            <Checkbox style={{ fontWeight: '400' }}>He leído y acepto los <Link href="#" style={{ fontWeight: 'bold' }} className='letraVerde' onClick={() => onDownloadTerminosYcondiciones()}> Términos y Condiciones y la Política de Privacidad</Link> de este sitio</Checkbox>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>

                                    <Col xs={0} sm={0} md={12} lg={12}>
                                        <br />
                                        <Button
                                            htmlType="button"
                                            style={{ width: "80%" }}
                                            shape="round"
                                            onClick={() => onVolver()}
                                        >
                                            Volver
                                        </Button>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={12}>
                                        <br />
                                        <Button
                                            type="primary"
                                            htmlType="button"
                                            style={{ width: "80%" }}
                                            shape="round"
                                            onClick={() => onCrearCuenta()}
                                            loading={formIsSubmiting}
                                        >
                                            Crear cuenta
                                        </Button>
                                    </Col>
                                </Row>
                                <p className="font-semibold text-muted" style={{ textAlign: 'center', fontWeight: 600 }}>
                                    ¿Ya tienes una cuenta?{" "}
                                    <Link to="#" onClick={() => keycloak.login({ redirectUri: `${process.env.REACT_APP_URL}/puente` })} className="text-dark font-bold letraNaranja">
                                        Iniciar Sesión
                                    </Link>
                                </p>
                            </Form>
                        </Col>
                        <Col xs={0} sm={0} md={0} lg={8} xl={8} xxl={10}>
                            <div style={{ width: '45%', position: 'fixed' }}>
                                <div className="right-section">
                                    <div className="background-image"></div>
                                </div>
                            </div>
                        </Col>

                    </Row>
                </Content>
            </Layout>
            <ModalExitoCrearCuenta redirectToLogin={true} fueCorrecto={accionCorrecta} isModalOpen={isModalOpen} handleOk={handleOk} tipoCuenta={"Persona"} poseeCuentaKeyCloack={existeEmailBd?.tieneCuentakeyCloack} />
        </>
    )
}

export default FormCrearCuentaPersonas