import { UserAddOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { Avatar, Button, Modal } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const ModalExitoCrearCuenta = ({ isModalOpen, handleOk, tipoCuenta, fueCorrecto, redirectToLogin }) => {
  const onAceptar = () => {
    if (fueCorrecto && redirectToLogin) {
      window.location.replace(process.env.REACT_APP_URL_ONBOARDING_PUBLICO)
    }
    else {
      handleOk()
    }
  }

  return (
    <div>
      <Modal
        closable={false}
        centered
        open={isModalOpen}
        onOk={handleOk}
        width={450}
        footer={[
          <Button className={tipoCuenta === 'Empresa' ? 'btnAzul' : ''} key="submit" type="primary" shape="round" onClick={() => onAceptar()}>
            Aceptar
          </Button>
        ]}
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
          {fueCorrecto ?
            <Avatar style={{ backgroundColor: tipoCuenta === 'Empresa' ? '#0F00D8' : '#00FF91', color: tipoCuenta === 'Empresa' ? 'white' : 'black' }} size={80} icon={<UserAddOutlined />} /> :
            <Avatar style={{ backgroundColor: '#FF4201' }} size={80} icon={<CloseCircleOutlined />} />
          }

          {fueCorrecto ? <h2 style={{ marginBottom: '5px' }}>¡Creación de cuenta {tipoCuenta} exitosa!</h2> : null}
          {fueCorrecto ?
            <p>
              {tipoCuenta === 'Empresa' ? 'Enviamos un correo al usuario administrador de la empresa para validar la creación' : 'Te enviamos un correo para validar su creación'}
            </p>
            :
            <p>
              ¡Ha ocurrido un error, intentelo nuevamente!
            </p>
          }
        </div>
      </Modal>
    </div>
  )
}

export default ModalExitoCrearCuenta